import React from "react";

class AdOverlay extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			show: true
		}
	}

	closeOverlay = (e) => {
		e.preventDefault()
		this.setState({show: false})
	}

	linkClick = (e) => {
		e.stopPropagation()
		this.setState({show: false})
	}

	render() {
		const { name, locale } = this.props;
		const catLink = (locale) ? `url(/images/pro-free-trial/cat-14days-${locale}.png)` : `url(/images/pro-free-trial/cat-14days-en.png)`;
		const catAd = {
			background: catLink,
			backgroundSize: 'contain',
			backgroundRepeat: 'no-repeat',
			height: '350px',
  			width: '420px',
		};

		if (name === undefined) throw "missing AdOverlay prop 'name'"

		if (this.state.show) {
			return(
				<div id="overlay-bg" className="ad-overlay-bg" onClick={this.closeOverlay}>
					<div className="ad-overlay-container">
						<a id="nvc-overlay-nord" className="ad-overlay-nord d-block pointer" target="_blank" href={`https://go.nordvpn.net/aff_c?offer_id=30&aff_id=30518&url_id=323&aff_sub=${name}`} onClick={this.linkClick}>
							<div id="overlay-close" className="ad-overlay-close pointer" onClick={this.closeOverlay}>
								X
							</div>
						</a>
						<a id="nvc-overlay-pro-free-trial" className="ad-overlay-pro-free-trial d-block pointer" target="_blank" href="https://store.bittorrent.com/849/purl-utprotrialweb14" onClick={this.linkClick} style={catAd}>
							<div id="overlay-close" className="ad-overlay-close pointer" onClick={this.closeOverlay}>
								X
							</div>
						</a>
					</div>
				</div>
			)
		} else {
			return(<></>)
		}
	}
}

export default AdOverlay;
